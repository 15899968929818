import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, AppBar, Container, Toolbar, Hidden, Typography, IconButton } from "@mui/material";
import { HomeRounded, MenuRounded, LibraryBooksRounded } from "@mui/icons-material";

import RightDrawerProvider from "./RightDrawerProvider";
import PianoIcon from "@mui/icons-material/Piano";
import PaletteIcon from "@mui/icons-material/Palette";
import theme from "../../styles/theme.js";

export default function HomePageNavBar({ location }) {
    const navigate = useNavigate();
    const pathName = window.location.pathname.split("/")[1];
    const [navbar, setNavbar] = useState(false);

    const brandmenus = [
        { title: "주은교육", link: "/" },
        { title: "브랜드", link: "/brand" },
        // { title: "어린음악대", link: "/littleband" }, // added
        // { title: "어린화가들", link: "/littleartists" }, // added
        // { title: "가맹문의", link: "/franchise" }, // added
        { title: "공지", link: "/notice" },
    ];
    const littlebandmenus = [
        { title: "어린음악대", link: "/littleband" },
        { title: "프로그램", link: "/littleband/program" },
        { title: "공지", link: "/littleband/notice" },
        //{ title: "브랜드", link: "/brand" }, // added
    ];
    const littleArtistsMenus = [
        { title: "어린화가들", link: "/littleartists" },
        { title: "프로그램", link: "/littleartists/program" },
        { title: "공지", link: "/littleartists/notice" },
        //{ title: "브랜드", link: "/brand" }, // added
    ];

    const filteredMenu = location => {
        switch (location.pathname) {
            case "/":
                return brandmenus;
            case "/brand":
                return brandmenus;
            case "/notice":
                return brandmenus;
            case "/littleband":
                return littlebandmenus;
            case "/littleband/program":
                return littlebandmenus;
            case "/littleband/notice":
                return littlebandmenus;
            case "/littleartists":
                return littleArtistsMenus;
            case "/littleartists/program":
                return littleArtistsMenus;
            case "/littleartists/notice":
                return littleArtistsMenus;
            case "/franchise":
                return brandmenus;
            // return [{ title: "가맹문의", link: "/franchise" }];
            default:
                return [];
        }
    };

    const [selectedMenu, setSelectedMenu] = useState(filteredMenu(location)[0]?.title);

    useEffect(() => {
        setSelectedMenu(filteredMenu(location).filter(li => li.link === location.pathname)[0]?.title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleOpenNavMenu = e => {
        setNavbar(e.currentTarget);
    };
    const handleCloseNavMenu = link => {
        navigate(link);
        setNavbar(false);
    };
    const handleChangeMenuColor = menu => {
        setSelectedMenu(menu.title);
        if (menu.link === "/franchise") {
            window.open("http://jueunedu.com", "가맹안내");
        } else {
            navigate(menu.link);
        }
    };
    const checkBrand = () => {
        switch (pathName) {
            case "littleband":
                return theme.palette.littleBand.main;
            case "littleartists":
                return theme.palette.littleArtist.main;
            default:
                return theme.palette.primary.main;
        }
    };

    const goToHome = () => {
        pathName === "littleband"
            ? navigate("/littleband")
            : pathName === "littleartists"
              ? navigate("/littleartists")
              : navigate("/");
    };

    const LOGO =
        pathName === "littleband"
            ? "/images/littleband/brandIntro/brandIntro-logo.svg"
            : pathName === "littleartists"
              ? "/images/littleartists/littleArtist_logo.svg"
              : "/jueun-edu-logo.svg";

    return (
        <AppBar
            position="static"
            elevation={0} // no shadow
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                backdropFilter: "blur(5px)",
                borderBottom: "2px solid",
                borderColor: "rgba(239, 239, 239, 0.2)",
            }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters variant="dense">
                    {/* 로고 - sm 이상 */}
                    {/* <Hidden mdDown> */}
                    <Stack
                        sx={{
                            display: { xs: "none", md: "flex" },
                            width: "100%",
                            height: "100px",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <Stack direction="row" alignItems="center">
                            <img
                                onClick={() => goToHome()}
                                src={LOGO}
                                alt="logo"
                                width="120px"
                                height="45.4px"
                                style={{ cursor: "pointer" }}
                            />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                sx={{ ml: 12 }}
                                spacing={{ md: 3, xl: 6 }}>
                                {filteredMenu(location).map(menu => {
                                    return (
                                        <Stack
                                            key={menu.title}
                                            onClick={() => handleChangeMenuColor(menu)}
                                            sx={{
                                                cursor: "pointer",
                                            }}>
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    color:
                                                        selectedMenu === menu.title ? checkBrand() : "constant.white",
                                                }}>
                                                {menu.title}
                                            </Typography>
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            {/* // 원복  */}
                            {/* <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    display={["littleband", "littleartists"].includes(pathName) ? "flex" : "none"}
                                    onClick={() => navigate("/")}
                                    sx={{
                                        border: "1px solid #111",
                                        borderColor: "primary.main",
                                        borderRadius: "18px",
                                        cursor: "pointer",
                                        width: "114px",
                                        height: "36px",
                                        color: "primary.main",
                                        ":hover": {
                                            backgroundColor: "primary.main",
                                            color: "white",
                                            border: "none",
                                        },
                                    }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Pretendard",
                                            fontSize: "16px",
                                            textAlign: "center",
                                        }}>
                                        회사소개
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    display={pathName === "littleband" ? "none" : "flex"}
                                    onClick={() => navigate("/littleband")}
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "littleBand.main",
                                        borderRadius: "18px",
                                        cursor: "pointer",
                                        width: "114px",
                                        height: "36px",
                                        color: "littleBand.main",
                                        ":hover": {
                                            backgroundColor: "littleBand.main",
                                            color: "white",
                                            border: "none",
                                        },
                                    }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Pretendard",
                                            fontSize: "16px",
                                            textAlign: "center",
                                        }}>
                                        어린음악대
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    display={pathName === "littleartists" ? "none" : "flex"}
                                    onClick={() => navigate("/littleartists")}
                                    sx={{
                                        border: "1px solid #111",
                                        borderColor: "littleArtist.main",
                                        borderRadius: "18px",
                                        cursor: "pointer",
                                        width: "114px",
                                        height: "36px",
                                        color: "littleArtist.main",
                                        ":hover": {
                                            backgroundColor: "littleArtist.main",
                                            color: "white",
                                            border: "none",
                                        },
                                    }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Pretendard",
                                            fontSize: "16px",
                                            textAlign: "center",
                                        }}>
                                        어린화가들
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => window.open("http://jueunedu.com", "가맹안내")}
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "secondary.main",
                                        borderRadius: "18px",
                                        cursor: "pointer",
                                        width: "114px",
                                        height: "36px",
                                        color: "secondary.main",
                                        ":hover": {
                                            backgroundColor: "secondary.main",
                                            color: "white",
                                            border: "none",
                                        },
                                    }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Pretendard",
                                            fontSize: "16px",
                                            textAlign: "center",
                                        }}>
                                        가맹안내
                                    </Typography>
                                </Stack> */}
                        </Stack>
                    </Stack>
                    {/* </Hidden> */}
                    {/* xs, sm용 Right Drawer */}
                    {/* <Hidden mdUp> */}
                    <Stack
                        sx={{
                            display: { xs: "flex", md: "none" },
                            flexDirection: "row",
                            width: "100%",
                            p: 1,
                            pt: 3,
                            pb: 2,
                        }}>
                        <Stack
                            sx={{
                                // py: 0.5,
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}>
                            {filteredMenu(location)
                                .filter(
                                    menu =>
                                        !(
                                            ["/", "/brand", "/notice"].includes(location.pathname) &&
                                            ["어린음악대", "어린화가들"].includes(menu.title)
                                        ),
                                )
                                .map(menu => {
                                    return (
                                        <Stack
                                            key={menu.title}
                                            onClick={() => handleChangeMenuColor(menu)}
                                            sx={{
                                                ml: 0,
                                                fontSize: "16px",
                                                color: selectedMenu === menu.title ? checkBrand() : "constant.white",

                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                mr: 2,
                                            }}>
                                            {menu.title}
                                        </Stack>
                                    );
                                })}
                        </Stack>
                        <Stack sx={{ flexGrow: 0 }}>
                            <IconButton onClick={handleOpenNavMenu}>
                                <MenuRounded sx={{ color: "constant.white" }} />
                            </IconButton>
                            <RightDrawerProvider
                                menu={MENUS}
                                open={navbar}
                                setOpen={setNavbar}
                                onLink={handleCloseNavMenu}
                                pathname={location.pathname}
                                topBgColor="#656465"
                                topHeight="60px"
                            />
                        </Stack>
                        {/* 대메뉴 배치 */}
                    </Stack>
                    {/* </Hidden> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

const MENUS = [
    {
        id: "main",
        menu_name: "주은교육",
        link: "/",
        hasDivider: true,
        icon: <HomeRounded />,
        level: 1,
        expand: false,
    },
    /* // 원복   */
    {
        id: "littleband",
        menu_name: "어린음악대",
        link: "/littleband",
        hasDivider: true,
        icon: <PianoIcon />,
        level: 1,
        expand: false,
    },
    {
        id: "littleartists",
        menu_name: "어린화가들",
        link: "/littleartists",
        hasDivider: false,
        icon: <PaletteIcon />,
        level: 1,
        expand: false,
    },
    {
        id: "franchise",
        menu_name: "가맹문의",
        link: "/franchise",
        hasDivider: false,
        icon: <LibraryBooksRounded />,
        level: 1,
        expand: false,
    },
];
