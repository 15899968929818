import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useFooterLink() {
    const initialLinks = useMemo(() => {
        return [
            {
                img: "youtube-icon.png",
                link: "https://www.youtube.com/@jueunedu",
                alt: "유튜브",
            },
            {
                img: "naver-icon.png",
                link: "https://blog.naver.com/jueun20100",
                alt: "네이버블로그",
            },
            {
                img: "instagram-icon.png",
                link: "https://www.instagram.com/ikidsband_ikidsart_jueun/",
                alt: "인스타그램",
            },
            {
                img: "kakaotalk-icon.png",
                link: "https://pf.kakao.com/_xktTxoM",
                alt: "카카오톡채널",
            },
        ];
    }, []);

    const location = useLocation();
    const [links, setLinks] = useState(initialLinks);

    useEffect(() => {
        if (
            location.pathname.includes("littleband") ||
            location.pathname.includes("musicgogo") ||
            location.pathname.includes("pianogogo")
        ) {
            setLinks([
                {
                    img: "youtube-icon.png",
                    link: "https://www.youtube.com/@user-ds5lj4lu6w",
                    alt: "유튜브",
                },
                {
                    img: "naver-icon.png",
                    link: "https://cafe.naver.com/jueun2010",
                    alt: "네이버카페",
                },
                {
                    img: "instagram-icon.png",
                    link: "https://www.instagram.com/ikidsband_ikidsart_jueun/",
                    alt: "인스타그램",
                },
                {
                    img: "kakaotalk-icon.png",
                    link: "https://pf.kakao.com/_xktTxoM",
                    alt: "카카오톡채널",
                },
            ]);
        } else if (location.pathname.includes("littleartist")) {
            setLinks([
                {
                    img: "youtube-icon.png",
                    link: "https://www.youtube.com/@user-zp2kq3mj9b",
                    alt: "유튜브",
                },
                {
                    img: "naver-icon.png",
                    link: "https://cafe.naver.com/jueunart",
                    alt: "네이버카페",
                },
                {
                    img: "instagram-icon.png",
                    link: "https://www.instagram.com/ikidsband_ikidsart_jueun/",
                    alt: "인스타그램",
                },
                {
                    img: "kakaotalk-icon.png",
                    link: "https://pf.kakao.com/_xktTxoM",
                    alt: "카카오톡채널",
                },
            ]);
        } else {
            setLinks([
                {
                    img: "youtube-icon.png",
                    link: "https://www.youtube.com/@jueunedu",
                    alt: "유튜브",
                },
                {
                    img: "naver-icon.png",
                    link: "https://cafe.naver.com/jueun2010",
                    alt: "네이버카페",
                },
                {
                    img: "instagram-icon.png",
                    link: "https://www.instagram.com/ikidsband_ikidsart_jueun/",
                    alt: "인스타그램",
                },
                {
                    img: "kakaotalk-icon.png",
                    link: "https://pf.kakao.com/_xktTxoM",
                    alt: "카카오톡채널",
                },
            ]);
        }
    }, [initialLinks, location]);

    return [links];
}
