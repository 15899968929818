import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Divider, Stack, Typography } from "@mui/material";

import { TitleComponents } from "../titleComponent/TitleComponents";
import ScrollButton from "../elements/ScrollButton";
import ScrollMouse from "../elements/ScrollMouse";
import useFooterLink from "../../hooks/useFooterLink";
import ContainerLayout from "./ContainerLayout";

export default function JumboTitlForMain({ children }) {
    const location = useLocation();
    const [info, setInfo] = useState({ title: <></>, subtitle: "", subtitleColor: "", backgroundImage: "url('')" });
    const [links] = useFooterLink();

    useEffect(() => {
        switch (location.pathname) {
            case "/":
                setInfo({
                    title: (
                        <>
                            <span>아이들의 눈으로</span>
                            <span>세상을 향합니다</span>
                        </>
                    ),
                    subtitle: "스마트예술교육 놀이터",
                    subtitleColor: "#FFF",
                    backgroundImage: "url('/images/jueun-edu/jumbo-background.webp')",
                });
                break;
            case "/littleband":
                setInfo({
                    title: (
                        <>
                            <span>즐기는 음악의 가치</span>
                            <span>NO.1 브랜드</span>
                            <span>어린음악대</span>
                        </>
                    ),
                    subtitle: "열 손가락으로 두뇌를 열다",
                    subtitleColor: "littleBand.main",
                    backgroundImage: "url('/images/littleband/jumbo-background.webp')",
                });
                break;
            case "/littleartists":
                setInfo({
                    title: (
                        <>
                            <span>컬러풀 통합미술</span>
                            <span>감성놀이터</span>
                            <span>어린화가들</span>
                        </>
                    ),
                    subtitle: "어린 예술가들이 꿈꾸는 행복한 미술",
                    subtitleColor: "littleArtist.main",
                    backgroundImage: "url('/images/littleartists/jumbo-background.webp')",
                });
                break;
            default:
                setInfo({
                    title: (
                        <>
                            <span>아이들의 눈으로</span>
                            <span>세상을 향합니다</span>
                        </>
                    ),
                    subtitle: "스마트예술교육 놀이터",
                    subtitleColor: "#FFF",
                    backgroundImage: "url('/images/jueun-edu/jumbo-background.webp')",
                });
                break;
        }
    }, [location]);

    return (
        <>
            <Stack
                sx={{
                    position: "relative",
                    minHeight: window.innerHeight,
                    backgroundImage: info.backgroundImage,
                    backgroundSize: "cover",
                    backgroundAttachment: { xs: "scroll", md: "fixed" },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}>
                {/* HomepageNavbar */}
                {children}
                <Stack
                    sx={{
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        backdropFilter: "blur(5px)",
                    }}
                />
                <TitleComponents
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 179px)",
                        left: "calc(50% - 152px)",
                        textAlign: "center",
                    }}>
                    {info.subtitle === "스마트예술교육 놀이터" ? (
                        <Stack>
                            <Typography
                                sx={{
                                    color: "white",
                                    textAlign: { xs: "start", sm: "center" },
                                    fontFamily: "S-CoreDream4",
                                    fontSize: { xs: 18, sm: 24 },
                                }}>
                                {info.subtitle}
                            </Typography>
                        </Stack>
                    ) : (
                        <TitleComponents.Subtitle typoStyle={{ color: info.subtitleColor }}>
                            {info.subtitle}
                        </TitleComponents.Subtitle>
                    )}
                    <TitleComponents.MainTitle typoStyle={{ color: "constant.white" }}>
                        {info.title}
                    </TitleComponents.MainTitle>
                </TitleComponents>
                {/* company only */}

                {location.pathname === "/" && (
                    <Stack
                        sx={{
                            width: "100%",
                            position: "absolute",
                            bottom: 100,
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Stack sx={{ px: 3, maxWidth: { xs: "400px", sm: "616px" } }}>
                            <img src="/images/jueun-edu/prize_four.png" alt="prize" />
                        </Stack>
                    </Stack>
                )}
                {/* md 이상 */}
                <ScrollButton
                    onClick={() => {
                        const element = document.getElementById("content");
                        element.scrollIntoView({ behavior: "smooth" });
                    }}
                />
                {/* md 미만 */}
                <ScrollMouse
                    onClick={() => {
                        const element = document.getElementById("content");
                        element.scrollIntoView({ behavior: "smooth" });
                    }}
                />
            </Stack>
            {/* links 위치이동 - 컬러부여 */}
            {location.pathname === "/" && (
                <>
                    <ContainerLayout bgColor="white">
                        <Stack
                            direction="row"
                            spacing={5}
                            sx={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                height: { xs: 56, lg: 118 },
                                width: "100%",

                                img: {
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                },
                            }}>
                            {links.map(l => (
                                <a key={l.alt} href={`${l.link}`} target="_blank" rel="noreferrer">
                                    <Stack sx={{ width: { xs: 40, sm: 50, md: 60, lg: 65 } }}>
                                        <img
                                            src={`/images/footer/${l.img}`}
                                            alt={`${l.alt}`}
                                            style={{ objectFit: "contain" }}
                                        />
                                    </Stack>
                                </a>
                            ))}
                        </Stack>
                    </ContainerLayout>
                    <Divider sx={{ width: "100%" }} />
                </>
            )}
            {location.pathname === "/" && (
                <Stack
                    sx={{
                        //border: "1px solid red",
                        mt: { xs: 0, xl: "200px" },
                        mb: 1,
                        mx: { xs: 0, md: 0, xl: 4 },
                        flexDirection: { xs: "column", xl: "row" },
                        justifyContent: "center",
                        alignItems: "center",
                        gap: { xs: 1, md: 3 },
                    }}>
                    <Stack>
                        <img
                            src="/images/jueun-edu/place1.png"
                            alt="place1"
                            style={{ width: "100%", objectFit: "contain" }}
                        />
                    </Stack>
                    <Stack>
                        <img
                            src="/images/jueun-edu/place2.png"
                            alt="place2"
                            style={{ width: "100%", objectFit: "contain" }}
                        />
                    </Stack>
                    <Stack>
                        <img
                            src="/images/jueun-edu/place3.png"
                            alt="place3"
                            style={{ width: "100%", objectFit: "contain" }}
                        />
                    </Stack>
                </Stack>
            )}
        </>
    );
}
